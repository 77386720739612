import React, {useEffect, useRef, useState} from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import "swiper/css/navigation"

import styles from "./styles.module.scss"
import "../../../styles/_V2/components/swiper.scss"

import data from "./data"

interface Props {
	className?: string
}

export default function AutomationMenu(props: Props) {
	const [isMobile, setIsMobile] = useState(false);
	const [menuData, setMenuData] = useState(data);

	const mobileMenuRef = useRef(null);
	const mobileMenuActiveItemRef = useRef(null);

	useEffect(() => {
		if (typeof window === 'undefined') return

		function onResize() {
			setIsMobile(window.innerWidth <= 767)
		}

		setTimeout(() => {
			if (mobileMenuRef.current && mobileMenuActiveItemRef.current) {
				mobileMenuRef.current.scrollLeft = mobileMenuActiveItemRef.current.offsetLeft - (mobileMenuRef.current.offsetWidth / 2) + (mobileMenuActiveItemRef.current.offsetWidth / 2);
			}
		}, 20)

		window.addEventListener('resize', onResize)

		onResize();

		setMenuData(menuData.map((menuItem) => {
			return {
				...menuItem,
				isActive: window.location.pathname === menuItem.href
			}
		}))

		return () => window.removeEventListener('resize', onResize)
	}, [])

	const onSlideChange = swiper => {
		// Сохраняю активный индекс как свойство объекта window,
		// чтобы при обновлении страницы меню прокручивалось к пункту текущей страницы
		window.automationMenuIndex = swiper.activeIndex
	}

	const initialActiveIndex =
		typeof window !== "undefined" &&
		(typeof window.automationMenuIndex === "number"
			? window.automationMenuIndex
			: menuData.findIndex(menuItem => menuItem.isActive))

	return (
		<nav className={classNames(styles.automationMenu, props.className)}>
			{
				!isMobile && (
					<Swiper
						className={styles.automationMenu__slider}
						modules={[Navigation]}
						initialSlide={initialActiveIndex}
						slidesPerView={"auto"}
						onSlideChange={onSlideChange}
					>
						{menuData.map(menuItem => (
							<SwiperSlide
								key={menuItem.text}
								className={styles.automationMenu__slide}
							>
								{
									menuItem.isActive ? (
										<span
											className={classNames(
												styles.automationMenu__link,
												styles.automationMenu__linkCurrent
											)}
										>
											{menuItem.text}
										</span>
									) : (
										<Link to={menuItem.href} className={styles.automationMenu__link}>
											{menuItem.text}
										</Link>
									)
								}

							</SwiperSlide>
						))}
					</Swiper>
				)
			}

			{
				isMobile && (
					<div
						className={styles.automationMenu__menu}
						ref={mobileMenuRef}
						data-mobile-menu={true}
					>
						{ menuData.map(menuItem => (
							<div
								key={menuItem.text}
								className={styles.automationMenu__slide}
								ref={menuItem.isActive ? mobileMenuActiveItemRef : null}
								data-mobile-menu-active={menuItem.isActive}
							>
								{
									menuItem.isActive ? (
										<span
											className={classNames(
												styles.automationMenu__link,
												styles.automationMenu__linkCurrent
											)}
										>
											{menuItem.text}
										</span>
									) : (
										<Link
											to={menuItem.href}
											className={styles.automationMenu__link}
										>
											{menuItem.text}
										</Link>
									)
								}

							</div>
						)) }
					</div>
				)
			}

		</nav>
	)
}
