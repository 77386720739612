export default [
	{
		text: "Кафе",
		href: "/automation/avtomatizaciya-kafe/",
	},
	{
		text: "Ресторан",
		href: "/automation/avtomatizaciya-restorana/",
	},
	{
		text: "Бар",
		href: "/automation/avtomatizaciya-bara/",
	},
	{
		text: "Кофейня",
		href: "/automation/avtomatizaciya-kofejni/",
	},
	{
		text: "Фудтрак",
		href: "/automation/avtomatizaciya-fudtraka/",
	},
	{
		text: "Кальянная",
		href: "/automation/avtomatizaciya-kalyannoj/",
	},
	{
		text: "Фастфуд",
		href: "/automation/avtomatizaciya-fastfood/",
	},
	{
		text: "Кулинария",
		href: "/automation/avtomatizaciya-kulinarii/",
	},
	{
		text: "Пекарня",
		href: "/automation/avtomatizaciya-pekarni/",
	},
	{
		text: "Столовая",
		href: "/automation/avtomatizaciya-stolovoy/",
	},
	{
		text: "Кондитерская",
		href: "/automation/avtomatizaciya-konditerskoy/",
	},
	{
		text: "Общепит",
		href: "/automation/avtomatizaciya-obshchepita/",
	},
	{
		text: "Пиццерия",
		href: "/automation/avtomatizaciya-piccerii/",
	},
	{
		text: "Суши",
		href: "/automation/avtomatizaciya-sushi/",
	},
	{
		text: "Паб",
		href: "/automation/avtomatizaciya-paba/",
	},
	{
		text: "Дарк-китчен",
		href: "/automation/dark-kitchen/",
	},
	{
		text: "Массовых мероприятий",
		href: "/automation/avtomatizaciya-stadiona/",
	},
	// {
	//   text: 'Фестиваль',
	//   href: '/automation/avtomatizaciya-stolovoy/', // TODO неправиьлная ссылка
	// }
]
