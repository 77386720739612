import React from "react"
import data from "./data"
import Benefits from "../../../components/_V2/Benefits"

interface Props {
	className?: string
}

export default function BarBenefits(props: Props) {
	return <Benefits data={data} className={props.className} />
}
