import * as React from "react"
import { SVGProps } from "react"

const Inventory = (props: SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 101 100"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect width="85" height="85" transform="translate(8.5 7)" fill="white" />
		<path
			d="M68.9735 19.3193C63.7153 16.1811 57.5681 14.3781 50.9998 14.3781C31.6025 14.3781 15.8779 30.1027 15.8779 49.5C15.8779 56.0063 17.6471 62.0994 20.7306 67.3245M33.2924 79.838C38.4914 82.879 44.5421 84.6219 50.9998 84.6219C70.3971 84.6219 86.1217 68.8972 86.1217 49.5C86.1217 42.9504 84.329 36.8196 81.2074 31.5715"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeMiterlimit="10"
			strokeLinecap="round"
		/>
		<path
			d="M60.3779 24.8084L71.1859 20.3085"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M66.686 9.50002L71.1859 20.308"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M41.6221 74.1917L30.8141 78.6915"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M35.314 89.4998L30.8141 78.6919"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M50.9998 28.4937L31.561 36.9589L50.9998 45.9728"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M50.9997 28.4937L70.4385 36.9589L50.9997 45.9728"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M60.0703 32.4439L40.9422 41.3137V50.1054"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M31.561 37.5859V61.4923L50.9998 70.5062"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M70.4385 37.3508L70.4385 61.4923L50.9997 70.5062"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M50.9829 70.0175V46.3829"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default Inventory
