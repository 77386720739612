import React from "react"
import data from "./data"
import AutomationCtaB from "../../../components/_V2/AutomationCtaB"

interface Props {
	className?: string
	request?: Function
}

export default function BarCtaForm(props: Props) {
	return (
		<AutomationCtaB
			data={data}
			className={props.className}
			isFry={false}
			request={props.request}
		/>
	)
}
