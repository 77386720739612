import * as React from "react"
import { SVGProps } from "react"

const PhoneBackdropBubble = (props: SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 432 134"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M37.2892 0.678953L398.813 23.5809C419.331 24.8807 434.314 43.5 431.196 63.8203L426.69 93.1776C424.128 109.875 410.005 122.359 393.119 122.853L44.0633 133.071C26.0607 133.598 10.5971 120.377 8.32005 102.511L0.357265 40.0339C-2.42182 18.2288 15.3516 -0.710758 37.2892 0.678953Z"
			fill="#F7F5FF"
		/>
	</svg>
)

export default PhoneBackdropBubble
