import * as React from "react"

const TakeMyMoney = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 106 97"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M58.8633 24.927L59.1365 28.0274C59.774 28.4688 61.0294 29.8321 60.9509 31.7545"
			stroke="#2A2D33"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M37.4178 43.3335L34.1367 57.7079"
			stroke="#2A2D33"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M34.1423 57.6904L27.6562 85.8862"
			stroke="#2A2D33"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M50.8066 59.2854L44.6406 86.0306"
			stroke="#2A2D33"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M66.4476 36.9238C70.628 42.4109 73.1008 48.9988 73.5702 55.8669M22.093 27.0659C15.6973 30.4147 10.4852 35.648 7.16234 42.0571C3.83945 48.4663 2.56629 55.7417 3.51525 62.8984C4.46421 70.0551 7.58945 76.7473 12.4678 82.069C17.3462 87.3907 23.742 91.0847 30.7894 92.651C37.8368 94.2173 45.1953 93.5801 51.8685 90.8258C58.32 88.163 63.8299 83.6458 67.7048 77.8538"
			stroke="#2A2D33"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M46.4749 31.8091L46.7117 30.8375C46.4088 30.7637 46.0889 30.8353 45.8464 31.0312L46.4749 31.8091ZM53.8448 33.6053L54.7603 33.203C54.635 32.9179 54.3841 32.7075 54.0816 32.6337L53.8448 33.6053ZM46.2381 32.7806L53.608 34.5769L54.0816 32.6337L46.7117 30.8375L46.2381 32.7806ZM52.9293 34.0076C53.1327 34.4704 53.2461 34.9826 53.2461 35.524H55.2461C55.2461 34.7004 55.073 33.9145 54.7603 33.203L52.9293 34.0076ZM53.2461 35.524C53.2461 37.608 51.5566 39.2974 49.4726 39.2974V41.2974C52.6612 41.2974 55.2461 38.7125 55.2461 35.524H53.2461ZM49.4726 39.2974C47.3886 39.2974 45.6992 37.608 45.6992 35.524H43.6992C43.6992 38.7125 46.2841 41.2974 49.4726 41.2974V39.2974ZM45.6992 35.524C45.6992 34.338 46.2452 33.2803 47.1033 32.5869L45.8464 31.0312C44.5385 32.088 43.6992 33.7083 43.6992 35.524H45.6992Z"
			fill="#2A2D33"
		/>
		<path
			d="M54.8477 33.3345L61.1067 31.8091C62.0262 32.5522 62.6143 33.6892 62.6143 34.9636C62.6143 37.2023 60.7994 39.0172 58.5607 39.0172"
			stroke="#2A2D33"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<circle cx={50.8187} cy={35.4132} r={1.34992} fill="#2A2D33" />
		<circle cx={60.0765} cy={35.397} r={1.34992} fill="#2A2D33" />
		<path
			d="M37.2201 43.3173C36.4966 43.2634 35.6064 42.9932 35.0207 42.5649C34.4349 42.1367 33.9809 41.5529 33.7101 40.8798C33.4393 40.2067 33.3624 39.4711 33.4883 38.7566C33.6142 38.042 33.9378 37.377 34.4224 36.837C34.9069 36.297 35.5331 35.9035 36.2299 35.7012C36.9267 35.4989 37.6662 35.4959 38.3647 35.6925C39.0631 35.8891 39.6924 36.2775 40.1814 36.8136C40.4736 37.1339 40.7086 37.4993 40.8786 37.8938"
			stroke="#2A2D33"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M57.6304 44.9313C57.6543 44.9322 57.6784 44.9327 57.7025 44.9327H61.6353C62.7799 44.9327 63.7077 43.9 63.7077 42.6262C63.7077 41.3523 62.7799 40.3196 61.6353 40.3196H57.9019C57.6723 38.3048 56.1266 36.7468 54.2539 36.7468"
			stroke="#2A2D33"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M61.1992 52.3034C62.5507 52.7716 64.0258 52.0556 64.494 50.7041C64.5328 50.5921 64.5635 50.4793 64.5863 50.3662C65.0589 48.0276 62.2874 46.2241 61.1992 44.1008V44.1008"
			stroke="#2A2D33"
			strokeWidth={2}
			strokeLinejoin="round"
		/>
		<path
			d="M52.8108 50.2802L52.1157 53.581C52.0215 54.0281 52.3173 54.457 52.6869 54.7258V54.7258C52.9088 54.8872 53.0968 55.0907 53.24 55.3248C53.3833 55.5589 53.479 55.8189 53.5218 56.0899C53.5646 56.361 53.5536 56.6379 53.4894 56.9047C53.4252 57.1715 53.309 57.423 53.1476 57.645C52.9862 57.8669 52.7826 58.0549 52.5486 58.1981C52.3145 58.3414 52.0545 58.4372 51.7834 58.48V58.48C51.3381 58.5503 50.8885 58.8084 50.7906 59.2486L50.3559 61.2042"
			stroke="#2A2D33"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M61.4835 52.4031C46.0153 48.4051 48.9158 48.9546 46.0152 48.4051C43.1146 47.8556 41.0074 48.9325 40.6969 50.5718C40.3863 52.211 42.2537 54.6653 46.8272 54.6653"
			stroke="#2A2D33"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M33.4296 39.467C33.4296 39.4761 33.4297 39.4851 33.4297 39.4942C29.2028 38.6034 26.0299 34.8526 26.0299 30.3606C26.0299 27.9123 26.9725 25.6841 28.5146 24.0192L23.3672 18.9428L29.0391 18.3712V12.5234L33.5364 16.9598C35.7235 13.7204 39.4287 11.5904 43.6315 11.5904C46.8989 11.5904 49.8656 12.8778 52.052 14.9731C52.0557 14.7816 52.0575 14.5897 52.0575 14.3973C52.0575 9.56736 50.8834 5.01164 48.805 1C52.9645 3.80509 56.3615 7.65442 58.6203 12.172C58.6504 11.6288 58.6656 11.0817 58.6656 10.531C58.6656 7.87807 58.3114 5.30792 57.6477 2.86521C62.1571 7.56365 65.1082 13.7691 65.6702 20.6508L65.6702 20.6613C65.6702 24.351 63.3086 25.0361 60.3954 25.0361C55.0478 24.829 44.1457 22.4048 39.3631 21.2186C41.2618 23.0074 42.5187 25.4698 42.7519 28.2239C41.2702 27.2105 39.5257 26.5532 37.6416 26.3754C39.9561 28.3748 41.4207 31.3311 41.4207 34.6296C41.4207 35.7503 41.2516 36.8314 40.9376 37.849C40.928 37.8562 40.9183 37.8633 40.9087 37.8705"
			stroke="#2A2D33"
			strokeWidth={2}
			strokeLinejoin="round"
		/>
		<path
			d="M50.3266 61.3485C49.6831 64.2058 45.5372 65.706 41.0665 64.6992C36.5958 63.6923 33.4932 60.5598 34.1367 57.7024"
			stroke="#2A2D33"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M48.1953 70.5588C48.7008 68.1742 49.6713 66.9927 52.0559 67.4982C52.0559 67.4982 53.429 67.847 54.1811 68.9872C55.0142 65.1731 53.9226 61.6271 52.0559 58.5627"
			stroke="#2A2D33"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M31.4125 69.5113C31.9972 66.7536 30.2355 64.044 27.4778 63.4594C25.9539 63.1363 24.4448 63.5297 23.3008 64.4116C23.3847 63.6892 23.5128 62.964 23.687 62.2391C25.2514 55.7316 30.1389 50.8927 36.1156 49.0906"
			stroke="#2A2D33"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M27.3751 54.595C18.4102 54.595 10.1884 57.7766 3.77734 63.0724"
			stroke="#2A2D33"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M52.0165 73.8804C52.267 75.3556 52.3974 76.8716 52.3974 78.4182C52.3974 83.142 51.1805 87.5816 49.043 91.4406"
			stroke="#2A2D33"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14.8533 75.5684L12.9453 82.1039"
			stroke="#2A2D33"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M53.1105 60.1887L62.0112 62.7924L80.4273 52.1599C80.0737 53.1834 79.8817 54.2822 79.8817 55.4259C79.8817 60.7698 84.0739 65.1345 89.349 65.4107C88.2148 66.5869 78.5443 76.392 70.4321 77.3665C61.6477 78.4218 52.1055 73.8584 52.1055 73.8584"
			stroke="#2A2D33"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M84.6753 42.7865C83.0277 36.4708 80.0156 28.0244 80.0156 28.0244C81.774 26.2911 87.5179 23.6456 96.4265 26.9304C96.4265 26.9304 94.4222 37.6774 94.584 44.6819M85.6746 48.012C86.4169 54.8622 85.4859 65.1096 85.4859 65.1096C89.6333 64.3339 92.8041 64.346 99.8726 65.1096C99.8726 65.1096 99.2844 62.9851 98.5012 60.8836"
			stroke="#2A2D33"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<rect
			x={92.8359}
			y={45.3855}
			width={9.87233}
			height={5.42077}
			rx={2.71038}
			transform="rotate(-25.9934 92.8359 45.3855)"
			stroke="#2A2D33"
			strokeWidth={2}
			strokeLinejoin="round"
		/>
		<rect
			x={92.8359}
			y={51.4077}
			width={9.87233}
			height={5.42077}
			rx={2.71038}
			transform="rotate(-25.9934 92.8359 51.4077)"
			stroke="#2A2D33"
			strokeWidth={2}
			strokeLinejoin="round"
		/>
		<rect
			x={92.8359}
			y={57.4446}
			width={9.87233}
			height={5.42077}
			rx={2.71038}
			transform="rotate(-25.9934 92.8359 57.4446)"
			stroke="#2A2D33"
			strokeWidth={2}
			strokeLinejoin="round"
		/>
		<path
			d="M85.6822 48.0319L89.3825 50.0462C90.6621 50.823 92.3291 50.4155 93.1059 49.1359C93.8827 47.8564 93.4752 46.1894 92.1957 45.4125L88.3214 43.0604C87.9241 42.8192 87.4895 42.6916 87.0557 42.6694C87.0517 42.6694 87.0477 42.6694 87.0438 42.6695C86.9015 42.6628 86.7593 42.6674 86.6186 42.683C85.3799 42.76 84.1807 43.163 83.1444 43.8546C81.9867 44.6274 81.0844 45.7257 80.5515 47.0107C80.0187 48.2957 79.8793 49.7097 80.1509 51.0738C80.4225 52.438 81.093 53.691 82.0776 54.6745C83.0622 55.658 84.3166 56.3278 85.6822 56.5992"
			stroke="#2A2D33"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M96.4766 43.2051C96.7595 39.4564 99.7908 35.3545 102.178 32.366L97.0725 30.0859"
			stroke="#2A2D33"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default TakeMyMoney
