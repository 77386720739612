import React, { useState } from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"

import PhoneBackdropBubble from "./assets/PhoneBackdropBubble"

import ConsultationCtaFormA from "../ConsultationCtaFormA"
import TakeMyMoney from "../../../pagesSections/index/CtaForm/assets/TakeMyMoney"
import TakeMyMoney2 from "../../../pagesSections/index/CtaForm/assets/TakeMyMoney2"

interface Props {
	className?: string
	data: {
		title: string
		subTitle: string
		description: string
		phone: {
			value: string
			text: string
		}
		ctaButtonText: string
	}
	isFry?: boolean
	request?: Function
}

export default function AutomationCtaB(props: Props) {
	const isFry = props.isFry !== undefined ? props.isFry : true

	const [isModalOpen, setIsModalOpen] = useState(false)

	const handleModalStatus = () => {
		setIsModalOpen(!isModalOpen)
	}

	return (
		<>
			<section className={classNames(styles.section, props.className)}>
				<div className={styles.content}>
					<div className={styles.titleWrapper}>
						<p className={styles.subTitle}>{props.data.subTitle}</p>
						<div className={styles.illustration}>
							{isFry ? (
								<TakeMyMoney className={styles.illustrationImage} />
							) : (
								<TakeMyMoney2 className={styles.illustrationImage} />
							)}

							<h2 className={styles.title}>{props.data.title}</h2>
						</div>
					</div>

					<p className={styles.description}>{props.data.description}</p>

					<div className={styles.buttonsWrapper}>
						<a
							className={styles.contactPhoneNumberButton}
							href={`tel:${props.data.phone.value}`}
						>
							<PhoneBackdropBubble className={styles.phoneBackdrop} />
							<span className={styles.phoneNumberButtonText}>
								{props.data.phone.text}
							</span>
						</a>
						<a
							className={styles.ctaButton}
							onClick={handleModalStatus}
							href={"#popup"}
						>
							{props.data.ctaButtonText}
						</a>
					</div>
				</div>
			</section>

			<ConsultationCtaFormA
				isOpen={isModalOpen}
				onClose={handleModalStatus}
				request={props.request}
			/>
		</>
	)
}
