import React from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"

interface Props {
	className?: string
	data: Benefit[]
}

interface Benefit {
	icon: JSX.Element
	title: string
	description: string
}

export default function Benefits(props: Props) {
	const renderBenefit = (item: Benefit) => {
		return (
			<article key={item.title} className={styles.benefit}>
				<div className={styles.benefit__icon}>{item.icon}</div>
				<h3 className={styles.benefit__title}>{item.title}</h3>
				<p className={styles.benefit__description}>{item.description}</p>
			</article>
		)
	}

	return (
		<section className={classNames(styles.section, props.className)}>
			{props.data.map(item => renderBenefit(item))}
		</section>
	)
}
