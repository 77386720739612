import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import styles from "./styles.module.scss"

import { pagesLinks } from "../../common/links"

export default [
	{
		title: "Программа для управления баром в облаке",
		description: (
			<p>
				Один онлайн-кабинет, где можно настроить все процессы бизнеса.
				В&nbsp;любой момент меняй акции или обновляй меню напитков&nbsp;&mdash;
				для этого не&nbsp;нужно быть в&nbsp;заведении. Все документы
				и&nbsp;накладные безопасно хранятся в&nbsp;облаке.
				<br />
				<br />
				Владелец бара может отслеживать продажи в&nbsp;мобильном приложении.
				О&nbsp;самых важных событиях сообщают моментальные пуш-уведомления,
				а&nbsp;выручка, средний чек и&nbsp;количество гостей за&nbsp;смену видны
				на&nbsp;первом экране. Автоматизируй бар и&nbsp;следи
				за&nbsp;показателями со&nbsp;смартфона.
			</p>
		),

		img: (
			<StaticImage
				className={styles.image1}
				src="./assets/bar-feature-1.png"
				alt="программа для бара"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,
		className: styles.feature1,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.reports.href,
	},
	{
		title: "Удобная CRM-система автоматизации для бара",
		description: (
			<p className={styles.smallDescription}>
				Привлекай гостей и&nbsp;увеличивай средний чек с&nbsp;CRM для бара.
				Создавай любые акции и&nbsp;программы лояльности: &laquo;1+1&raquo;,
				&laquo;комбо&raquo;, &laquo;скидки по&nbsp;расписанию&raquo; и&nbsp;бонусы
				за&nbsp;заказ. С&nbsp;автоматизацией бара руководитель получает отчёты для
				аналитики, которые помогут найти прибыльные и&nbsp;неприбыльные программы.
				Легко проверить, как изменились продажи после начала акции, какие блюда
				пользуются спросом.
			</p>
		),

		img: (
			<StaticImage
				className={styles.image2}
				src="./assets/bar-feature-2.png"
				alt="crm для бара"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: styles.feature2,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.marketing.href,
	},
	{
		title: "Контроль персонала",
		description: (
			<>
			<p>
				Автоматизация бара поможет оценить: кто из&nbsp;сотрудников отстаёт
				по&nbsp;продажам, сработала&nbsp;ли новая система мотивации персонала.
			</p>
			<p>
				Программа для кафе и&nbsp;баров предотвращает ошибки и&nbsp;воровство.
				Чтобы исключить злоупотребления, ограничь доступ сотрудников
				к&nbsp;потенциально опасным возможностям. Установи разные права доступа
				для управляющего и&nbsp;официантов, настрой контроль за&nbsp;наличными
				в&nbsp;кассе. Подключи Телеграм-бот и&nbsp;получай оповещения
				о&nbsp;сомнительных операциях в&nbsp;заведении: отмене пречека, возврате
				чека или чеках с&nbsp;фиксированными скидками.
			</p>
				</>
		),

		img: (
			<StaticImage
				className={styles.image3}
				src="./assets/bar-feature-3.png"
				alt="программа учета бара"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		reverse: true,

		className: styles.feature3,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.staff.href,
	},
	{
		title: "Техкарты и расчёт себестоимости",
		description: (
			<p className={styles.smallDescription}>
				Используй техкарты для закусок и&nbsp;раскладки для коктейлей. Система
				сама рассчитывает себестоимость блюд и&nbsp;напитков, наценку, КБЖУ.
				Ведение техкарт с&nbsp;софтом для бара позволяет организовать
				автоматический учёт ингредиентов. Отслеживай изменение цен
				и&nbsp;контролируй, чтобы блюдо не&nbsp;перестало быть выгодным. Цена
				поставщика изменила&nbsp;&mdash; система сообщит об&nbsp;этом
				и&nbsp;выделит выгодные и&nbsp;невыгодные закупки разным цветом.
				<br />
				<br />
				Программа автоматизации кафе, бара и&nbsp;ресторана Quick Resto
				показывает, какие позиции в&nbsp;меню заведения приносят больше всего
				прибыли. Контролируй фудкост и&nbsp;повышай маржинальность.
			</p>
		),

		img: (
			<StaticImage
				className={styles.image2}
				src="./assets/bar-feature-4.png"
				alt="автоматизация кафе бара"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: styles.feature4,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.menu.href,
	},
	{
		title: "Программа для автоматизации продаж блюд и напитков",
		description: (
			<p>
				На&nbsp;кассовом терминале есть подсказки для барменов, которые помогут
				увеличить продажи и&nbsp;повысить средний чек. Настрой акции
				&laquo;комбо&raquo; или скидку по&nbsp;расписанию, добавь
				модификаторы&nbsp;&mdash; дополнительные платные ингредиенты
				к&nbsp;основной позиции. На&nbsp;кухне не&nbsp;упустят особые пожелания
				гостя благодаря прямой связи кассового терминала и&nbsp;экрана повара.
				<br />
				<br />
				Прокачивай сервис с&nbsp;программой для бара и&nbsp;ресторана.
				Нужно добавить сироп&nbsp;&mdash; официант делает комментарий к&nbsp;заказу.
				На&nbsp;кухне не&nbsp;упустят пожелания гостя благодаря прямой связи кассового
				терминала и&nbsp;экрана повара.
				<br />
				<br />
				С автоматизацией можно не останавливать процесс обслуживания даже
				при отключении электричества или интернета. Когда сеть
				появится&nbsp;&mdash; касса передаст данные в&nbsp;систему.
			</p>
		),

		img: (
			<StaticImage
				className={styles.image5}
				src="./assets/bar-feature-5.png"
				alt="касса для бара"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,
		className: styles.feature5,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.terminal.href,
	},
	{
		title: "Внедрение системы автоматизации под ключ",
		description: (
			<p>
				Мы понимаем, что у руководителя много дел перед открытием, поэтому
				автоматизация бара может стать нашей задачей. Заполним техкарты,
				зарегистрируем кассу в ФНС и&nbsp;подключим ЕГАИС. Подскажем, как
				настроить систему для дополнительных продаж и защиты от махинаций
				персонала.
			</p>
		),

		img: (
			<StaticImage
				className={styles.image6}
				src="./assets/bar-feature-6.png"
				alt="Алексей Гаврилов"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: styles.feature6,
		theme: "white",
	},
]
