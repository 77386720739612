import * as React from "react"
import { SVGProps } from "react"

const Egais = (props: SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 101 100"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect width="85" height="85" transform="translate(8 7)" fill="white" />
		<path
			d="M59.298 48.8751C56.8616 43.61 55.596 37.8702 55.596 32.0687L55.596 14C55.596 12.8954 54.7006 12 53.596 12H47.404C46.2995 12 45.404 12.8954 45.404 14L45.404 32.0687C45.404 37.8702 44.1384 43.61 41.702 48.8751C39.2656 54.1402 38 59.8801 38 65.6816L38 81.8751C38 84.0842 39.7909 85.8751 42 85.8751L59 85.8751C61.2091 85.8751 63 84.0842 63 81.8751L63 65.6815C63 59.8801 61.7344 54.1402 59.298 48.8751Z"
			stroke="#2A2D33"
			strokeWidth="3"
		/>
		<path
			d="M67.0322 85.8751L78.9678 85.8751C81.1947 85.8751 83 84.0842 83 81.8751L83 46.3456C83 44.7469 82.4484 43.1964 81.4369 41.9519L78.8738 38.7983C77.8623 37.5538 77.3107 36.0033 77.3107 34.4047L77.3107 13C77.3107 12.4477 76.8594 12 76.3027 12H69.6973C69.1406 12 68.6893 12.4477 68.6893 13L68.6893 34.4047C68.6893 36.0033 68.1377 37.5538 67.1262 38.7983L64.5631 41.9519C63.5516 43.1964 63 44.7469 63 46.3456V64.1103"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M36.5192 47.1259L33.8368 39.5215C32.8567 36.7432 32.356 33.8185 32.356 30.8724L32.356 14C32.356 12.8954 31.4606 12 30.356 12H25.8032C24.6986 12 23.8032 12.8954 23.8032 14L23.8032 30.8724C23.8032 33.8185 23.3025 36.7432 22.3224 39.5216L19.64 47.1259C18.6599 49.9042 18.1592 52.8289 18.1592 55.7751L18.1592 81.8751C18.1592 84.0842 19.95 85.8751 22.1592 85.8751H34C36.2091 85.8751 38 84.0842 38 81.8751"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M63.1597 48.4531H83M63.1597 77H83"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M68.9438 20.6873H77.1124"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M21.7637 20.6873H34.3955"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M49.4155 25.5089H54.5841"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M38.9385 58.519H58.0615"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M38.9385 73.5037H62.0615"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M18.1592 63.519H32C32.5523 63.519 33 63.9668 33 64.519V73.5214"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M18.1592 77.5037H33"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default Egais
