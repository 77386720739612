import { contacts } from "../../../pages-data/_V2/common/contacts"

export default {
	title: "Открыть бар быстрее?",
	subTitle: "позвонить и оформить заявку",
	description:
		"Мы зарегистрируем онлайн-кассу и подключим ЕГАИС. Начни работу, не отвлекаясь на «операционку».",
	phone: contacts.phone,
	ctaButtonText: "Заказать звонок",
}
