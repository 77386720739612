import React from "react"

import Inventory from "./assets/Inventory"
import Egais from "./assets/Egais"

export default [
	{
		icon: <Inventory />,
		title: "Инвентаризация бара",
		description:
			"Программа учёта бара упрощает инвентаризацию: после продажи напитка или блюда система списывает ингредиенты со склада по граммам. Вы видите то количество продуктов, которое должно остаться на складе.",
	},
	{
		icon: <Egais />,
		title: "ЕГАИС для бара",
		description:
			"Quick Resto поддерживает ЕГАИС — программу учёта алкоголя, закупок и вскрытия тары. Отправляйте отчёты в ФСРАР в несколько кликов. Благодаря ЕГАИС вы сможете понимать, сколько продали алкоголя.",
	},
]
